<template>
  <div class="login">
    <Dialog @close="$emit('close')" :title="'Login'" :width="350" :height="375">
      <v-card-text>
        <v-form v-model="form.valid"  @keyup.enter.native="signIn" @keyup.esc.native="$emit('close')">
          <v-text-field id="password" label="Email" name="login"  color="secondary" prepend-icon="mdi-account" type="text" :rules="form.email.rules" v-model="form.email.value"></v-text-field>
          <v-text-field :label="$t('Password')"  name="password" color="secondary" prepend-icon="mdi-lock" type="password" :rules="form.password.rules" v-model="form.password.value"></v-text-field>
          <a class="reset-password" @click="resetPassword">{{ $t('Redefine password') }}</a>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex flex-column mx-auto">
        <Button :disabled="!form.valid" class="sign-in mx-auto mb-2" color="secondary" :loading="loading" :height="40" @click="signIn">{{ $t('Sign In') }}</Button>
        <div class="social-signup d-flex justify-space-around mt-4">
          <Button class="mr-1" :color="'#1877f2'" :height="30" @click="socialSignIn('facebook')">
            <v-icon class="facebook-icon">mdi-facebook</v-icon><span class="ml-4">{{ `${$t('Sign in with')} Facebook` }}</span>
          </Button>
          <Button class="ml-1" :color="'#ff8f00'" :height="30" @click="socialSignIn('google')">
            <v-icon class="google-icon">mdi-google</v-icon><span class="ml-3">{{ `${$t('Sign in with')} Google` }}</span>
          </Button>
        </div>
      </v-card-actions>
      <!--<ErrorDialog v-if="error.length" :message="error" @close="error =''" />-->
    </Dialog>
   
  </div>
</template>
<script>

export default {
  name: 'Login',
  components: {
    Button: () => import('@/components/ui/Button'),
    Dialog: () => import('@/components/ui/Dialog'),
  },
  data: () => ({
    form: {
      email: { 
        value: '',
        rules: [
          v => !!v || 'Campo obrigatório',
          v => /.+@.+\..+/.test(v) || 'Formato do e-mail inválido',
        ]
      },
      password: {
        value: '',
        rules: [
          v => !!v || 'Campo obrigatório',
        ]
      },
      valid: false
    },  
    loading: false
  }),
  methods: {
    async signIn() {
      this.loading = true
      await this.$store.dispatch('auth/signIn', { email: this.form.email.value , password: this.form.password.value })
      this.loading = false
      this.close()
    },
    resetPassword () { 
      this.$store.dispatch('auth/resetPassword', this.form.email.value) 
    },
    async socialSignIn (provider) { 
      this.loading = true
      await this.$store.dispatch(`auth/socialSingUpOrIn`, { provider, intent: 'signIn' })
      this.loading = false
      this.close()
    }, 
    close () {
      if (this.$store.getters['auth/auth'].uid) 
        this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>

  .v-messages {
    margin-bottom: 15px !important;
  }

  .reset-password {
    &:hover { text-decoration: underline; }
  }

  ::v-deep {
    .v-btn__content {
      width: inherit
    }
    .error--text {
      color: #ffae00 !important;
      caret-color: #ffae00 !important;
    } 
  }

  .v-card__actions {
    button {
      &.sign-in { 
        font-size: 17px !important; 
        width: 100% !important; 

      }
    }
  }

  .social-signup {
    width: 100%;
    
    button {
      flex: 1;
      letter-spacing: 0;
      font-family: 'Nunito';

      span { 
        font-size: 12px; 
        color: white;
        text-shadow: 1px 1px 1px black;
      }

      .v-icon {
        position: absolute;

        &.facebook-icon { 
          left: 15px;
          font-size: 21px; 
        }
        &.google-icon { 
          left: 20px;
          font-size: 15px; 
        }
      }
    }
  }
</style>